import moment from "moment";
import {useEffect, useRef, useState} from "react";
import {Dropdown} from "../Dropdown/Dropdown";
import {DropdownLabel} from "../DropdownLabel/DropdownLabel";
import {SelectItem} from "../SelectItem/SelectItem";
import styles from "./DateSelector.module.scss";
import {useTranslation} from "react-i18next";

interface IDateTimeSelector {
    date: Date | undefined;
    setDate: (date: Date) => void;
    highlighted?: boolean;
    minYear?: number;
    maxYear?: number;
    preset?: "bootstrap";
    disabled?: boolean;
}

const defaultMinYear = 2020;
const defaultMaxYear = moment().year();

export const DateSelector = ({
                                 date,
                                 setDate,
                                 highlighted,
                                 minYear = defaultMinYear,
                                 maxYear = defaultMaxYear,
                                 preset,
                                 disabled
                             }: IDateTimeSelector) => {
    const {t} = useTranslation();
    const yearSelector = useRef<HTMLDivElement>(null);
    const [yearSelectorOpen, setYearSelectorOpen] = useState<boolean>(false);
    const calendarSelector = useRef<HTMLDivElement>(null);
    const [calendarSelectorOpen, setCalendarSelectorOpen] = useState<boolean>(false);

    const years = useRef<number[]>(Array.from({length: maxYear - minYear + 1}, (_, i) => i + minYear).reverse());

    const [internalDate, setInternalDate] = useState(date === undefined ? moment() : moment(date));
    useEffect(() => {
        setInternalDate(date === undefined ? moment() : moment(date));
    }, [date]);
    const decrementMonth = () => {
        setInternalDate(moment(internalDate).subtract(1, "month"));
    };

    const incrementMonth = () => {
        setInternalDate(moment(internalDate).add(1, "month"));
    };

    const startWeekdayOfMonth = internalDate.clone().startOf("month").day() === 0 ? 7 : internalDate.clone().startOf("month").day();

    const now = moment();
    const isInCurrentMonth = internalDate.month() === now.month() && internalDate?.year() === now.year();
    return (
        <div className={`${styles.container} ${highlighted ? styles.highlighted : ""}`}>
            <div
                ref={yearSelector}
                onClick={() => !disabled && setYearSelectorOpen(!yearSelectorOpen)}
                className={`${preset === "bootstrap" ? `form-control ${styles.formControlBootstrap}` : ""}  ${disabled ? styles.disabled : ""}`}
            >
                <DropdownLabel
                    label={(internalDate.format("YYYY")) ?? t("year")}
                    open={yearSelectorOpen}
                    preset={preset}
                />
                {yearSelectorOpen &&
                    <Dropdown anchor={yearSelector} setOpen={setYearSelectorOpen}>
                        <div className={styles.yearSelector}>{years.current.map(year => (
                            <SelectItem
                                key={`year-${year}`}
                                label={year.toString()}
                                onClick={() => {
                                    setDate(internalDate.year(year).toDate());
                                    setYearSelectorOpen(false);
                                }}
                            />
                        ))}</div>
                    </Dropdown>
                }
            </div>
            <div
                ref={calendarSelector}
                onClick={calendarSelectorOpen ? undefined : () => {
                    if (disabled) return;
                    setCalendarSelectorOpen(!calendarSelectorOpen);
                }}
                className={`${preset === "bootstrap" ? `form-control ${styles.formControlBootstrap}` : ""}  ${disabled ? styles.disabled : ""}`}
                style={{
                    ...(preset === "bootstrap" && {marginLeft: 8})
                }}
            >
                <DropdownLabel
                    label={(date && internalDate.format("MMM DD")) ?? t("day")}
                    open={calendarSelectorOpen}
                    preset={preset}
                />
                {calendarSelectorOpen &&
                    <Dropdown anchor={calendarSelector} setOpen={setCalendarSelectorOpen} className={styles.calendar}>
                        <div className={styles.calendarHeader}>
                            <div onClick={decrementMonth} className={styles.timeSelectorButton}>
                                &lt;
                            </div>
                            <div>
                                {internalDate.format("MMMM")}
                            </div>
                            <div onClick={incrementMonth} className={styles.timeSelectorButton}>
                                &gt;
                            </div>
                        </div>
                        <div className={styles.calendarGrid}>
                            {Array.from({length: startWeekdayOfMonth - 1}).map((_, i) => <div key={`h-${i}`}
                                                                                              className={styles.cell}/>)}
                            {Array.from({length: internalDate.daysInMonth()}).map((_, i) => {
                                const isToday = isInCurrentMonth && now.date() === i + 1;
                                const isSelected = date && internalDate.isSame(moment(date).date(i + 1), "date");
                                return (
                                    <div
                                        key={i}
                                        className={`${styles.cell} ${styles.contentCell} ${isToday ? styles.currentDate : ""} ${isSelected ? styles.selectedDate : ""}`}
                                        onClick={() => {
                                            setDate(internalDate.date(i + 1).toDate());
                                            setCalendarSelectorOpen(false);
                                        }}
                                    >
                                        {(i + 1).toString()}
                                    </div>
                                );
                            })}
                        </div>
                    </Dropdown>
                }
            </div>
        </div>
    );
};
