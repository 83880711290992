import * as React from "react";
import {useContext, useEffect, useRef} from "react";
import styles from "./LiveZones.module.scss";
import {ILiveBoat} from "../LiveHome/LiveHome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import {barColor, hrZoneColors5} from "../AnalyticsPanel/AnalyticsPanel";
import ResponsiveContext from "../../../contexts/ResponsiveContext";

const chartOptions = {
    accessibility: {
        enabled: false
    },
    chart: {
        type: 'bar',
        animation: false
    },
    plotOptions: {
        bar: {
            animation: {
                duration: 0,
            },
        },
        series: {
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            states: {
                hover: {
                    enabled: false
                },
                inactive: {
                    opacity: 1
                }
            },
            animation: {
                duration: 0,
            },

            dataLabels: {
                enabled: true,
                format: '{y}%'
            }
        },
    },
    title: {
        text: '',
    },
    yAxis: {
        title: {
            text: ''
        },
        min: 0,
        max: 100,
    },
    legend: {
        enabled: false,
    }
};

export const LiveZones = ({boat}: { boat: ILiveBoat }) => {
    const {t} = useTranslation();
    const {windowSize} = useContext(ResponsiveContext);
    const categories = [
        "90-100%",
        "80-90%",
        "70-80%",
        "60-70%",
        "50-60%"
    ];
    const chartRefs = useRef<any[]>([]);
    const setChartRef = (i: number) => (el: any) => chartRefs.current[i] = el;
    useEffect(() => {
        // highcharts need to be resized manually
        for (const key in chartRefs.current) {
            chartRefs.current[key]?.chart.reflow();
        }
    }, [windowSize]);
    let chartRefIndex = 0;
    return <div className={styles.chart}>
        <HighchartsReact
            ref={setChartRef(chartRefIndex++)}
            highcharts={Highcharts}
            updateArgs={[true, true, false]}
            options={{
                ...chartOptions,
                title: {
                    text: t("hr zones"),
                },
                xAxis: {
                    categories: categories
                },
                series: [{
                    data: categories.map((_, i) => {
                        return {
                            y: boat.athletes[0].hrZones?.[i],
                            color: hrZoneColors5[i]
                        }
                    }),
                }]
            }}
        />
        <HighchartsReact
            ref={setChartRef(chartRefIndex++)}
            highcharts={Highcharts}
            updateArgs={[true, true, false]}
            options={{
                ...chartOptions,
                title: {
                    text: t("force zones adaptive max"),
                },
                xAxis: {
                    categories: categories
                },
                series: [{
                    data: categories.map((_, i) => {
                        return {
                            y: boat.athletes[0].forceZones?.[i],
                            color: barColor[i]
                        }
                    }),
                }]
            }}
        />
    </div>
}
