import * as React from "react";
import {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import styles from "./LivePage.module.scss";
import {Outlet} from "react-router-dom";
import LiveContext from "../../contexts/LiveContext";
import {LiveNavbar} from "../../components/paddlemate/LiveNavbar/LiveNavbar";

export const LivePage = () => {
    const {t} = useTranslation();
    const {setLive} = useContext(LiveContext);

    useEffect(() => {
        setLive(true);
        return () => setLive(false);
    }, [setLive]);

    return (
        <div className={styles.container}>
            <LiveNavbar/>
            <div className={styles.content}>
                <Outlet/>
            </div>
        </div>
    );
};
