import {apiConfig} from "./apiConfig";
import axios from "axios";
import {IApiLiveData} from "../typings/IApiLiveData";
import {ILiveData} from "../typings/ILiveData";
import {getTrainingSportFromString, TrainingSport} from "../typings/TrainingSport";

const liveAxiosApi = axios.create({
    baseURL: apiConfig.liveApiUrl
});

const apiLiveDataTransformer = (data: IApiLiveData): ILiveData => {
    const res: ILiveData = {
        ...data,
        distance: parseFloat(data.distance),
        forces: data.forces.map(x => parseFloat(x)),
        hrs: data.hrms.map(x => parseFloat(x)).map(x => x === 0 ? undefined : x),
        speed: parseFloat(data.speed),
        strokeRate: parseFloat(data.strokecount),
        cloudId: data.cloudid,
        sport: getTrainingSportFromString(data.sporttype)
    };
    if (data.lat && data.lon) {
        res.location = {
            lat: parseFloat(data.lat),
            lng: parseFloat(data.lon)
        }
    }
    if (data.pulsezones) {
        res.hrZones = data.pulsezones.map(x => x.map(y => parseFloat(y)))
    }
    if (data.forcezones) {
        res.forceZones = data.forcezones.map(x => x.map(y => parseInt(y)))
    }
    if (data.forcestreams1) {
        res.forceSeriesLeft = data.forcestreams1.map(x => x.map(y => parseInt(y)))
    }
    if (data.forcestreams2) {
        res.forceSeriesRight = data.forcestreams2.map(x => x.map(y => parseInt(y)))
    }
    return res
}

export const LiveApi = {
    getData: async (cloudId: string): Promise<ILiveData> => {
        const res = await liveAxiosApi.get<IApiLiveData>(`/api/live/${cloudId}`);
        return apiLiveDataTransformer(res.data);
    }
}
