import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import styles from "./LiveForce.module.scss";
import {ILiveBoat} from "../LiveHome/LiveHome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useTranslation} from "react-i18next";
import ResponsiveContext from "../../../contexts/ResponsiveContext";
import {isTwoPaddle} from "../../../typings/TrainingSport";
import {forceSeriesColors} from "../AnalyticsPanel/StandardGraphs/StandardGraphs";
import {max} from "../../../utils/arrayUtils";
import {AthleteToggle} from "../AnalyticsPanel/AthleteToggle/AthleteToggle";
import {IAthlete} from "../../../typings/IAthlete";
import {getLiveShortName} from "../../../utils/liveHelper";

export const LiveForce = ({boat}: { boat: ILiveBoat }) => {
    const {t} = useTranslation();
    const {windowSize} = useContext(ResponsiveContext);
    const chartRefs = useRef<any[]>([]);
    const setChartRef = (i: number) => (el: any) => chartRefs.current[i] = el;
    useEffect(() => {
        // highcharts need to be resized manually
        for (const key in chartRefs.current) {
            chartRefs.current[key]?.chart.reflow();
        }
    }, [windowSize]);
    const [selectedAthleteIndexes, setSelectedAthleteIndexes] =
        useState<number[]>(boat?.athletes!.map((_, i) => i) || []);
    let chartRefIndex = 0;
    const toggleSelectedTraining = (index: number) => {
        setSelectedAthleteIndexes(x => {
            if (x.includes(index)) {
                if (x.length > 1)
                    return x.filter(y => y !== index);
            } else
                return [...x, index];
            return x;
        });
    };

    const forceSeries = boat.sport && selectedAthleteIndexes.flatMap(x => [
        {
            data: boat.athletes[x].forceSeriesLeft,
            color: forceSeriesColors[(x * (isTwoPaddle(boat.sport!) ? 2 : 1))],
            dataGrouping: {
                approximation: "max"
            }
        },
        {
            data: boat.athletes[x].forceSeriesRight,
            color: forceSeriesColors[(x * (isTwoPaddle(boat.sport!) ? 2 : 1) + 1) % forceSeriesColors.length],
            dataGrouping: {
                approximation: "max"
            }
        }
    ]).filter(x => x.data);

    const forceMax = forceSeries && max(
        forceSeries.map(x => x.data!).flat());

    return <div
        className={styles.container}>
        <div className={styles.chart}>
            <HighchartsReact
                ref={setChartRef(chartRefIndex++)}
                highcharts={Highcharts}
                updateArgs={[true, true, false]}
                options={{
                    accessibility: {
                        enabled: false
                    },
                    chart: {
                        type: "line",
                        animation: false
                    },
                    plotOptions: {
                        line: {
                            animation: {
                                duration: 0,
                            },
                        },
                        series: {
                            pointInterval: 10,
                            marker: {
                                enabled: false,
                                states: {
                                    hover: {
                                        enabled: false
                                    }
                                }
                            },
                            states: {
                                hover: {
                                    enabled: false
                                },
                                inactive: {
                                    opacity: 1
                                }
                            },
                            animation: {
                                duration: 0,
                            }
                        },
                    },
                    title: {
                        text: "",
                    },
                    xAxis: {
                        tickWidth: 1,
                        labels: {
                            enabled: true,
                            formatter: (self: any) => {
                                const v = self.axis.max - self.value;
                                return `${(v / 1000).toFixed(3)} s`;
                            },
                        },
                        reversed: true,
                        minPadding: 0,
                        maxPadding: 0,
                        startOnTick: true,
                        endOnTick: true
                    },
                    yAxis: {
                        title: undefined,
                        labels: {
                            formatter: function (self: any) {
                                const val = Math.round((self.value + Number.EPSILON) * 100) / 100;
                                return `${val} N`;
                            }
                        },
                        min: -50,
                        max: Math.max(100, forceMax || 100)
                    },
                    series: forceSeries,
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        enabled: false,
                    },
                }}
            />
            {forceMax && <div style={{position: "absolute", right: 20, top: 20}}>
                <div className={styles.avgMax}>{t("max")}: {forceMax.toFixed(0).replace('-0', '0')}N</div>
            </div>}
        </div>
        {boat?.athletes?.length > 1 && <div className={styles.legend}>
            {boat?.athletes!.map((x, i) =>
                <AthleteToggle key={i}
                               isSelected={selectedAthleteIndexes.includes(i)}
                               shortName={false}
                               onClick={() => toggleSelectedTraining(i)}
                               athlete={{firstName: getLiveShortName(x.name), lastName: ""} as any as IAthlete}
                               sport={boat.sport!}
                               index={i}/>
            )}
        </div>}
    </div>
}
