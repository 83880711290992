import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Offcanvas, OffcanvasBody} from "reactstrap";
import styles from "./LiveNavbar.module.scss";
import {NavButton} from "../../base/NavButton/NavButton";
import {mdiAccessPoint, mdiClose, mdiCog, mdiHome, mdiListBox} from "@mdi/js";
import {Link, useLocation} from "react-router-dom";
import Icon from "@mdi/react";

export const LiveNavbar = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    const toggle = () => setIsOpen(!isOpen);
    const navBar = <Nav className={styles.nav} navbar>
        <NavItem>
            <NavButton
                icon={mdiAccessPoint}
                title={"Live"}
                link={'/live'}
            />
        </NavItem>
        <NavItem>
            <NavButton
                icon={mdiCog}
                title={t('live settings')}
                link={'/live/settings'}
            />
        </NavItem>
        <NavItem className={"d-block d-xl-none"}>
            <NavButton
                icon={mdiClose}
                title={t('close live')}
                link={'/'}
            />
        </NavItem>
    </Nav>

    return (
        <Navbar className={styles.container} expand={"xl"} dark={true}>
            <div className={styles.liveText}>
                LIVE
            </div>
            <Link to={"/"} className={"navbar-brand"}>
                <img className={styles.logo} alt="" src="/icons/logos/paddlemate_logo_feher.png"/>
            </Link>
            <NavbarToggler onClick={toggle}/>
            <div className={styles.defaultNavbar}>{navBar}</div>
            <Offcanvas isOpen={isOpen} toggle={toggle} direction="end" className={styles.openCollapse}>
                <button
                    type="button"
                    onClick={toggle}
                    className={`btn-close btn-close-white ${styles.close}`}
                />
                <OffcanvasBody className={styles.canvasBody}>
                    {navBar}
                </OffcanvasBody>
            </Offcanvas>
            <Link to="/" className={`${styles.closeButton} btn btn-outline-secondary d-none d-xl-block`} role="button">
                <Icon path={mdiClose} size={0.8}/>{t('close live')}</Link>
        </Navbar>
    )
}
