import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./LiveHome.module.scss";
import AthletesContext from "../../../contexts/AthletesContext";
import {Spinner} from "../../base/Spinner/Spinner";
import {Api} from "../../../utils/api";
import {LiveApi} from "../../../utils/liveApi";
import {ILiveData} from "../../../typings/ILiveData";
import {TrainingSport} from "../../../typings/TrainingSport";
import {max} from "../../../utils/arrayUtils";
import Icon from "@mdi/react";
import {mdiChartBar, mdiMap} from "@mdi/js";
import {LiveCharts} from "../LiveCharts/LiveCharts";
import {LiveMap} from "../LiveMap/LiveMap";
import {LiveBoat} from "../LiveBoat/LiveBoat";
import moment from "moment/moment";
import {Card} from "reactstrap";
import {Link} from "react-router-dom";
import UserContext from "../../../contexts/UserContext";


export interface ILiveAthleteStat {
    hr: number | null,
    force: number | null,
}

export interface ILiveBoatStat {
    speed: number | null,
    strokeRate: number | null,
    location: { lat: number, lng: number } | null;
}

export interface ILiveBoat {
    cloudId: string;
    isActive: boolean;
    color: string;
    createdAt?: string;
    distance?: number;
    speed?: number;
    strokeRate?: number;
    location?: { lat: number, lng: number };
    sport?: TrainingSport,
    athletes: ILiveBoatAthlete[];
    stats?: ILiveBoatStat[];
}

export interface ILiveBoatAthlete {
    name: string;
    hr?: number;
    force?: number;
    maxForce?: number;
    hrZones?: number[]
    forceZones?: number[],
    forceSeriesLeft?: number[],
    forceSeriesRight?: number[],
    stats?: ILiveAthleteStat[];
}

let statsByCloudId: { [key: string]: ILiveBoatStat[] } = {};
let athleteStatsByLocalId: { [key: string]: ILiveAthleteStat[] } = {};
const statsSize = 15 * 60;

export const LiveHome = () => {
    const {t} = useTranslation();
    const {athletes} = useContext(AthletesContext);
    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [liveAthletes, setLiveAthletes] = useState<{
        color: string;
        name: string;
        cloudId: string;
        on: boolean;
    }[]>([]);
    const [selectedBoatId, setSelectedBoatId] = useState<string>();
    const [boats, setBoats] = useState<ILiveBoat[]>([]);
    const [tabState, setTabState] = useState("");
    const [lastBoatSelectTime, setLastBoatSelectTime] = useState(0);

    useEffect(() => {
        const get = async () => {
            setLoading(true);
            try {
                const las = await Api.getLiveAthletes(true);
                setLiveAthletes([...las.map(x => ({
                    cloudId: x.athlete.cloudId,
                    name: x.athlete.name,
                    color: x.color,
                    on: x.on
                })), {
                    cloudId: user?.cloudId ?? "",
                    name: user?.name ?? t("me"),
                    color: "#3ab3e5",
                    on: true
                }]);
                if (las.length === 0)
                    setLoading(false);
                statsByCloudId = {};
                athleteStatsByLocalId = {};
            } catch (e) {
                setLoading(false);
                throw e;
            }
        };
        get().catch(console.log);
    }, [t, user?.cloudId, user?.name]);

    useEffect(() => {
        let timeOutRef: any;
        let stopped = false;
        const update = async () => {
            if (liveAthletes.length > 0)
                setLoading(false);
            const promises = liveAthletes.map(async liveAthlete => {
                return await LiveApi.getData(liveAthlete.cloudId);
            })
            const promiseSettledResults = await Promise.allSettled(promises);
            const liveDatas = promiseSettledResults
                .filter(x => x.status === "fulfilled")
                .map(x => (x as PromiseFulfilledResult<ILiveData>).value);
            const emptyAthleteStat: ILiveAthleteStat = {
                hr: null,
                force: null
            };
            const emptyBoatStat: ILiveBoatStat = {
                speed: null,
                strokeRate: null,
                location: null
            };
            for (const key of Object.keys(statsByCloudId)) {
                if (statsByCloudId[key].length > statsSize)
                    statsByCloudId[key].shift();
                statsByCloudId[key].push(emptyBoatStat);
            }
            for (const key of Object.keys(athleteStatsByLocalId)) {
                if (athleteStatsByLocalId[key].length > statsSize)
                    athleteStatsByLocalId[key].shift();
                athleteStatsByLocalId[key].push(emptyAthleteStat);
            }
            setBoats(liveAthletes.map<ILiveBoat>(la => {
                const liveData = liveDatas.find(x => x.cloudId === la.cloudId);
                if (liveData) {
                    const createdAt = (liveData.createdAt && moment.utc(liveData.createdAt)) || null;
                    const isActive = (createdAt && createdAt.diff(moment(), "hours") > -1)!!;
                    //TODO refactor: move out of map()
                    if (!statsByCloudId[la.cloudId]) {
                        statsByCloudId[la.cloudId] = Array.from({length: statsSize}, () => emptyBoatStat);
                    }
                    statsByCloudId[la.cloudId][statsByCloudId[la.cloudId].length - 1] = {
                        speed: liveData.speed,
                        strokeRate: liveData.strokeRate,
                        location: liveData.location || null
                    } as ILiveBoatStat;
                    return {
                        cloudId: la.cloudId,
                        isActive,
                        color: la.color,
                        createdAt: liveData.createdAt,
                        distance: liveData.distance,
                        speed: liveData.speed,
                        strokeRate: liveData.strokeRate,
                        location: liveData.location,
                        sport: liveData.sport,
                        athletes: liveData.names.map((_, i) => {
                            const localId = `${la.cloudId}-${i}`;
                            //TODO refactor: move out of from map
                            if (!athleteStatsByLocalId[localId]) {
                                athleteStatsByLocalId[localId] = Array.from({length: statsSize}, () => emptyAthleteStat);
                            }
                            athleteStatsByLocalId[localId][athleteStatsByLocalId[localId].length - 1] = {
                                hr: liveData.hrs[i],
                                force: liveData.forces[i]
                            } as ILiveAthleteStat;
                            return ({
                                name: liveData.names[i],
                                hr: liveData.hrs[i],
                                force: liveData.forces[i],
                                maxForce: max(athleteStatsByLocalId[localId].slice(-30).map(x => x.force || 0)),
                                hrZones: liveData.hrZones?.[i],
                                forceZones: liveData.forceZones?.[i],
                                forceSeriesLeft: liveData.forceSeriesLeft?.[i],
                                forceSeriesRight: liveData.forceSeriesRight?.[i],
                                stats: athleteStatsByLocalId[localId],
                            });
                        }),
                        // athletes: [...Array(8).keys()].map(x => ({
                        //     name: "P Athlete",
                        //     hr: 120,
                        //     force: 100,
                        //     maxForce: 100,
                        //     hrZones: [],
                        //     forceZones: [],
                        //     forceSeriesLeft: [],
                        //     forceSeriesRight: [],
                        //     stats: [],
                        // })),
                        stats: statsByCloudId[la.cloudId],
                    };
                } else {
                    return {
                        cloudId: la.cloudId,
                        isActive: false,
                        color: la.color,
                        athletes: [
                            {
                                name: la.name
                            }
                        ]
                    }
                }
            }))
        };

        let startTimeout: () => void;
        const callback = () => {
            update().catch(console.log)
                .finally(startTimeout);
        }

        startTimeout = () => {
            if (stopped) return;
            timeOutRef = setTimeout(callback, 1000);
        }
        startTimeout();
        return () => {
            if (timeOutRef)
                clearTimeout(timeOutRef);
            stopped = true;
        };
    }, [liveAthletes]);

    const toggleTab = (tab: string) => {
        setTabState(x => x === tab ? "" : tab);
    }

    const selectedBoat = boats.find(x => x.cloudId === selectedBoatId);
    const activeBoats = boats.filter(x => x.isActive);
    const handleBoatClick = (cloudId: string) => {
        setSelectedBoatId(cloudId);
        setLastBoatSelectTime(Date.now())
    };

    const abi = selectedBoat && activeBoats.indexOf(selectedBoat);
    const selectedActiveBoatIndex = (abi === undefined || abi < 0) ? undefined : abi;

    function getMapPath() {
        let mapPath = selectedBoatId !== undefined && statsByCloudId[selectedBoatId] ?
            statsByCloudId[selectedBoatId]
                .map(x => x.location)
                .filter(x => x) : [];
        //return mapPath;
        if (mapPath.length > 1) { // take locations that are close to the previous
            const filtered: any[] = [];

            function haversineDistanceKm(p1: any, p2: any) {
                const R = 6371; // Radius of the Earth in km
                const rlat1 = p1.lat * (Math.PI / 180);
                const rlat2 = p2.lat * (Math.PI / 180);
                const difflat = rlat2 - rlat1;
                const difflon = (p1.lng - p2.lng) * (Math.PI / 180);
                return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2)
                    + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
            }

            let lastElement = mapPath[mapPath.length - 1];
            for (let i = mapPath.length - 1; i >= 0; i--) {
                const distanceThresholdM = 2000;
                if (haversineDistanceKm(mapPath[i], lastElement) * 1000 < distanceThresholdM) {
                    filtered.push(mapPath[i])
                    lastElement = mapPath[i];
                } else break;
            }

            return filtered;
        } else {
            return mapPath;
        }
    }

    return <>
        {(athletes === null || loading) &&
            <div className={styles.loading}>
                <Spinner/>
            </div>
        }
        {athletes != null && athletes.length === 0 &&
            <div className={styles.noAthletes}>
                {t("no athletes")}
            </div>
        }
        {!(athletes === null || loading || athletes.length === 0) &&
            <>
                {liveAthletes.length === 0 &&
                    <div className={styles.noAthletes}>
                        {t("live no athletes")}
                        <Link className="btn btn-primary mt-2" to={"/live/settings"}>{t("live settings")}</Link>
                    </div>
                }
                {liveAthletes.length > 0 &&
                    <div className={styles.container}>
                        <div className={styles.height100}>
                            <div className={`${styles.boats}`} style={{
                                ...(tabState !== "" && {flex: 1, marginBottom: "2em"}),
                            }}>
                                {boats.sort((a, b) =>
                                    b.isActive as any as number - (a.isActive as any as number)).map((x, i) =>
                                    <LiveBoat key={i}
                                              boat={x}
                                              selected={x.cloudId === selectedBoatId}
                                              onClick={() => handleBoatClick(x.cloudId)}/>
                                )}
                            </div>
                            {tabState !== "" &&
                                <Card className={styles.bottomPanel}>
                                    {tabState === "charts" &&
                                        <LiveCharts boat={selectedBoat}/>
                                    }
                                    {tabState === "map" &&
                                        <LiveMap boats={activeBoats}
                                                 path={getMapPath()}
                                                 selectedBoatId={selectedActiveBoatIndex}
                                                 lastBoatSelectTime={lastBoatSelectTime}
                                        />
                                    }
                                </Card>
                            }
                        </div>
                    </div>
                }
            </>
        }
        <div className={styles.bottomButtons}>
            <a role="button" className={
                `btn ${tabState === "charts" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
               onClick={() => toggleTab("charts")}>
                <Icon path={mdiChartBar} size={1}/>
                {t("charts")}
            </a>
            <a role="button" className={
                `btn ${tabState === "map" ? "btn-secondary" : `btn-outline-secondary ${styles.buttonNotActive}`}`}
               onClick={() => toggleTab("map")}>
                <Icon path={mdiMap} size={1}/>
                {t("map")}
            </a>
        </div>
    </>;
};
