import * as React from "react";
import {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import AthletesContext from "../../../contexts/AthletesContext";
import {Spinner} from "../../base/Spinner/Spinner";
import styles from "./LiveSettings.module.scss";
import {Card, FormGroup, Input} from "reactstrap";
import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    Placement,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions
} from "@floating-ui/react";
import {ILiveAthlete} from "../../../typings/ILiveAthlete";
import {Api} from "../../../utils/api";
import UserContext from "../../../contexts/UserContext";

const Athlete = ({athlete, loading, onChange}: any) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState<Placement | null>(null);

    const {
        refs,
        floatingStyles,
        context,
        placement: resultantPlacement
    } = useFloating({
        placement: placement ?? "bottom",
        open,
        onOpenChange: setOpen,
        middleware: [offset(4), flip(), shift()],
        whileElementsMounted: autoUpdate
    });

    const {getReferenceProps} = useInteractions([
        useClick(context),
        useDismiss(context),
    ]);

    useEffect(() => {
        if (open) {
            setPlacement(resultantPlacement);
        } else {
            setPlacement(null);
        }
    }, [open, resultantPlacement]);

    const presetColors = [
        "#ff0000",
        "#70ff70",
        "#0000ff",
        "#ff9900",
        "#502b00",
        "#c800ff",
        "#207905",
        "#000272",
        "#fff000",
        "#9a0202",
    ];

    return <Card
        className={styles.athlete}>
        <div className={styles.name}>{athlete.name}</div>
        {loading && <div className={styles.loading}><Spinner size={24}/></div>}
        {!loading && <>
            <div style={{flex: 1}}></div>
            <div className={styles.formGroup}>
                <div className={styles.label}>{t("color")}</div>
                <div>
                    <div className={styles.color} style={{backgroundColor: athlete.color}}
                         ref={refs.setReference}
                         {...getReferenceProps()}
                    ></div>
                </div>
            </div>
            <FloatingPortal>
                {open && (
                    <FloatingFocusManager context={context} modal={false}>
                        <Card
                            innerRef={refs.setFloating}
                            className={styles.colorPickerFloating}
                            style={floatingStyles}
                        >
                            <div className={styles.colorInnerContainer}>
                                {presetColors.map((x) => (
                                    <button
                                        key={x}
                                        style={{background: x}}
                                        onClick={() => {
                                            onChange({...athlete, color: x});
                                            setOpen(false);
                                        }}
                                    />
                                ))}</div>
                        </Card>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
            <div className={styles.formGroup}>
                <div className={styles.label}>{t("show")}</div>
                <FormGroup className={styles.switch} switch>
                    <Input
                        type="switch"
                        checked={!!athlete.on}
                        onChange={() => {
                            onChange({...athlete, on: !athlete.on});
                        }}
                    />
                </FormGroup>
            </div>
        </>}
    </Card>
}

export const LiveSettings = () => {
    const {t} = useTranslation();
    const {athletes} = useContext(AthletesContext);
    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [liveAthletes, setLiveAthletes] = useState<{ [key: number]: ILiveAthlete }>({});
    const [updatingAthleteIds, setUpdatingAthleteIds] = useState<Set<number>>(new Set());

    const athletesWithSelf = useMemo(() => {
        if(!athletes || !user) return null;
        return [...athletes, {
            id: user.id,
            name: user.name
        }]
    }, [athletes, user]);

    useEffect(() => {
        const get = async () => {
            setLoading(true);
            try {
                const las = await Api.getLiveAthletes();
                setLiveAthletes(Object.fromEntries(las.map(x => [x.athlete.id, x])));
            } catch (e) {
                throw e;
            } finally {
                setLoading(false);
            }
        };
        get().catch(console.log);
    }, []);

    const handleAthleteChange = (athlete: ILiveAthlete & { id: number }) => {
        const update = async () => {
            setUpdatingAthleteIds(x => {
                const set = new Set(x);
                set.add(athlete.id);
                return set;
            })
            try {
                const liveA = liveAthletes[athlete.id];
                let las: ILiveAthlete;
                if (liveA) {
                    las = await Api.updateLiveAthlete(athlete.id, athlete.color, athlete.on);
                } else {
                    las = await Api.addLiveAthlete(athlete.id);
                }
                setLiveAthletes(x => ({
                    ...x,
                    [athlete.id]: las
                }));
            } catch (e) {
                throw e;
            } finally {
                setUpdatingAthleteIds(x => {
                    const set = new Set(x);
                    set.delete(athlete.id);
                    return set;
                })
            }
        };
        update().catch(console.log);
    };
    return (
        <>
            {(athletesWithSelf === null || loading) &&
                <div className={styles.loading}>
                    <Spinner/>
                </div>
            }
            {athletesWithSelf != null && athletesWithSelf.length === 0 &&
                <div className={styles.noAthletes}>
                    {t("no athletes")}
                </div>
            }
            {!(athletesWithSelf === null || loading || athletesWithSelf.length === 0) && <div className={styles.container}>
                <div className={styles.nestedContainer}>
                    {athletesWithSelf.map(athlete => (
                    <Athlete
                        key={athlete.id}
                        athlete={{
                            ...athlete,
                            color: liveAthletes[athlete.id]?.color ?? "#ffffff",
                            on: liveAthletes[athlete.id]?.on!!
                        }}
                        loading={updatingAthleteIds.has(athlete.id)}
                        onChange={handleAthleteChange}
                    />
                ))}
                </div>
            </div>}
        </>
    );
};
